import React from "react"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"

export default function Layout({ children }) {
  return (
    <main>
        <header>
        <h1><Link to="/">Hit Your Steps</Link></h1>
        <StaticImage src="../images/icon.png" alt="Hit Your Steps App Icon" placeholder="blurred" width={60} height={60} class="icon"/>
        </header>
        {children}
        <footer>
        Created by <a href="https://www.stephenkeable.co.uk">Stephen Keable</a> | <Link to="/privacy">Privacy Policy</Link> | <a href="https://usefathom.com/ref/FQPZBY">Analytics by Fathom</a> | <a href="https://m.do.co/c/b79e037fc0d2">Hosted by Digital Ocean</a>
        </footer>
        <script src="https://cdn.usefathom.com/script.js" data-site="DNKERPVJ" defer></script>
    </main>
  )
}